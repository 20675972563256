export const pageview = (url) => {
    let isDevelopment = true
    if (process.env.NODE_ENV !== 'development') {
      isDevelopment = false
    }
    // console.log('**** DEBUG **** Calling pageview')
    if (isDevelopment) return
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'pageview',
      page: url
    })
  }
  
  export const event = (data) => {
    console.log('dataLayer Event', data)
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(data)
    }
  }
  