"use client"
const GTM_ID = 'GTM-NLR9JJS'
import { pageview, event } from "utils/gtm"
import { usePathname, useSearchParams } from "next/navigation"
import Script from "next/script"
import { useEffect, useState } from "react"


function getVerticalScrollPercentage(elm) {
  var p = elm.parentNode
  return (elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight) * 100
}


export default function Analytics() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const [currentPosition, setCurrentPosition] = useState(-1)



  useEffect(() => {
    if (pathname) {
      pageview(pathname)
      setCurrentPosition(-1)
    }
  }, [pathname, searchParams])

  useEffect(() => {

    const handleScroll = async function(e) {
      let P = getVerticalScrollPercentage(document.body)
      let tmp = 0
      if (P < 25) {
        tmp = 0
      } else if (P < 50) {
        tmp = 25
      } else if (P < 75) {
        tmp = 50
      } else if (P < 100) {
        tmp = 75
      } else {
        tmp = 100
      }
      if (tmp > currentPosition) {
        setCurrentPosition(tmp)
        event({ "event": "GAevent", "eventCategory": "scroll", "eventAction": tmp, "eventLabel": "profundidad" });
      } else {
      }
    }

    if (typeof window !== "undefined") {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('scroll', handleScroll)
      }
    }

  })

  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <Script
        id="gtm-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${GTM_ID}');
  `,
        }}
      />
    </>
  )
}
